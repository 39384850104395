import { Carousel } from "antd";

import { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
const contentStyle = {
  width: "93%",
  margin: "0 auto",
  color: "#fff",
  textAlign: "center",
  background: "#364d79",
  borderRadius: "30px",
};

const IndexCarousel = () => {
  const [sliders, setSliders] = useState([]);
  useEffect(() => {
    const fetchSliders = async () => {
      try {
        const response = await axios.get(
          `${config.baseUrl}/api/slider_images/`
        );
        if (response.data && response.data.length > 0) {
          setSliders(response.data);
        }
      } catch (error) {}
    };

    fetchSliders();
  }, []);

  const getSliderImages = (slider) => {
    return ["image", "image1", "image2", "image3"]
      .map((key) => slider[key])
      .filter((image) => image !== null);
  };

  return (
    <>
      <Carousel
        autoplay
        style={{ backgroundColor: "white" }}
        arrows={true}
        autoplaySpeed={5000}
      >
        {sliders.flatMap((slider, sliderIndex) =>
          getSliderImages(slider).map((image, imageIndex) => (
            <div key={`${sliderIndex}-${imageIndex}`}>
              <img
                src={image}
                alt={`product ${sliderIndex + 1}-${imageIndex + 1}`}
                style={contentStyle}
              />
            </div>
          ))
        )}
      </Carousel>
    </>
  );
};

export default IndexCarousel;
