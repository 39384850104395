import React, { useState } from "react";
import { Button, Card, Divider, Spin } from "antd";
import OrderList from "../OrderList/orderlist";
import OrderStatus from "../OrderStatus/orderstatus";
import axios from "axios";
import config from "../config";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import Receipt from "../Receipt/Receipt";
import CryptoPayment from "../Payment/Bitcoin";
// import Express from "../Express/Express";

// Function to aggregate data
function groupByProductType(data) {
  const result = [];
  const groupedData = {};

  data.forEach((item) => {
    const productName = item.product.state;
    const productPrice = parseFloat(item.product.price); // Ensure price is numeric

    // If product type hasn't been added to groupedData, create a new object
    if (!groupedData[productName]) {
      groupedData[productName] = {
        name: item.product.name,
        quantity: 0,
        price: productPrice, // Initialize total price
      };
    }

    // Update product quantity
    groupedData[productName].quantity += 1;
  });
  for (const [key, value] of Object.entries(groupedData)) {
    result.push({
      key: key,
      name: value.name,
      price: value.price,
      quantity: value.quantity,
    });
  }

  return result;
}

const OrderDetailElement = ({ checkout_hash }) => {
  const [order_status, setOrderStatus] = useState("waiting_payment");
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [orderData, setOrderData] = useState({
    orderId: "",
    date: "",
    items: [],
    total: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${config.baseUrl}/api/orderdetail/${checkout_hash}/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        if (response.data.length === 0) {
          navigate("/orderform");
        } else {
          setOrderStatus(response.data["order_status"]);
          setProducts(response.data["cart_items"]);
          //set orderId
          const groupedProducts = groupByProductType(
            response.data["cart_items"]
          );
          setOrderData({
            orderId: response.data["order_number"],
            date: response.data["date"],
            items: groupedProducts,
            total: response.data["checkout_price"],
            actual_discount_amount: response.data["actual_discount_amount"],
            payment_address: response.data["payment_address"],
          });
        }
      } catch (error) {
        message.error("Error fetching data:", error);
        navigate("/orderform");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [checkout_hash, navigate]);
  return (
    <Spin spinning={loading} tip="Loading...">
      <Card
        title={
          <span style={{ fontSize: "30px", fontWeight: "bold" }}>
            Order Detail
          </span>
        }
        style={{ width: "93%", maxWidth: 1200, margin: "15px auto" }}
        hoverable
      >
        <Receipt orderData={orderData} />
        <Divider />
        <CryptoPayment bitcoinAddress={orderData.payment_address} />
        <Divider />
        <Card title="Order Status" hoverable>
          <OrderStatus status={order_status} />
        </Card>
        <Divider />
        <Card title="Order List">
          <OrderList products={products} />
        </Card>
        <Divider />
        <Button
          type="primary"
          onClick={() => navigate("/myorder")}
          style={{ width: "100%", height: "50px" }}
        >
          Back to My Order
        </Button>
      </Card>
    </Spin>
  );
};

export default OrderDetailElement;
